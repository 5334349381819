import React, { useEffect, useState } from 'react'
import user1 from '../../images/user1.png';
import { useDispatch, useSelector } from "react-redux";
import { addAppClient, changeClientProfile, editAppClient, fetchAppClient } from "../../store/actions/authAction";
import Alert from "../alert/alert";
import { deleteSocial, fetchSocialAccounts, saveFacebook } from "../../store/actions/socialAccount";
import Youtube from "./Youtube";
import LinkedInConnect from "./LinkedInConnect";
import VimeoConnect from "./VimeoConnect";
import RedditConnect from "./RedditConnect";
import TwitterConnect from "./TwitterConnect";
import TelegramConnect from "./TelegramConnect";
import SweetAlert from "react-bootstrap-sweetalert";
import FacebookConnect from "./FacebookConnect";
import { useHistory } from "react-router-dom";
import WordpressConnect from "./WordpressConnect";
import MediumConnect from "./MediumConnect";
import ReactTooltip from "react-tooltip";


function AddFrom(props) {

  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const socialsData = useSelector(state => state.social.accounts);

  const [socials, setSocials] = useState(socialsData);
  const [checkAccount, setCheckAccount] = useState(false);
  const [clientId, setClientID] = useState(null);
  const [profileStatus, setProfileStatus] = useState(false);

  const [openCustomSweet, setOpenCustomSweet] = useState(false);

  const [loader, setLoader] = useState(false);
  const [client, setClient] = useState({
    name: null,
    email: null,
    password: null,
    earning: null,
    frequency: "weekly",
    company: null,
    description: null,
    send: false
  });
  const [passwordError, setPasswordError] = useState('');


  const onCheckMail = (e) => {
    if (client.send === false) {
      setClient({ ...client, send: true });
    } else {
      setClient({ ...client, send: false });
    }
  }
  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*]/.test(password);

    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChars;
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setClient({ ...client, [name]: value });

    if (name === 'password') {
      if (!validatePassword(value)) {
        setPasswordError('Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.');
      } else {
        setPasswordError('');
      }
    }
  };
  /** SweetAlert */
  const [button, setButton] = useState('Delete');
  const [showDelete, setShowDelete] = useState(false);
  const [socialId, setSocialId] = useState({
    id: null
  })

  const onButtonClick = (e, id) => {
    e.preventDefault();
    setShowDelete(true);

    setSocialId({ ...socialId, id: id });
  }

  const onConfirm = (e) => {
    setButton('Please wait...');

    dispatch(deleteSocial(socialId, setShowDelete, setButton))
  }

  const onCancel = (e) => {
    setShowDelete(false);
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    dispatch(addAppClient(client, setLoader, setCheckAccount, setClientID, setOpenCustomSweet));
  }

  useEffect(() => {
    setSocials(socialsData);
  }, [socialsData]);

  const [clientPropID, setClientPropID] = useState(null);

  const [checkClient, setCheckClient] = useState(false);
  const [editClient, setEditClient] = useState({
    id: null,
    name: null,
    email: null,
    password: null,
    earning: null,
    frequency: null,
    company: null,
    description: null,
    profile: null
  });
  const onInputEditChange = (e) => {
    const { name, value } = e.target;
    setEditClient({ ...editClient, [name]: value });

    if (name === 'password') {
      if (!validatePassword(value)) {
        setPasswordError('Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.');
      } else {
        setPasswordError('');
      }
    }
  };

  useEffect(() => {
    if (Object.entries(props).length !== 0) {
      setCheckClient(true);
      setCheckAccount(true);

      if (auth.user.client === "1") {
        setEditClient({
          ...editClient,
          id: auth.user.id,
          name: auth.user.name,
          email: auth.user.email,
          password: null,
          earning: auth.user.earning,
          frequency: auth.user.frequency,
          company: auth.user.company,
          description: auth.user.description,
          profile: auth.user.thumbnail !== '' ? auth.user.thumbnail : auth.user.image
        })
      } else {
        const params = props.id.match.params;
        setClientPropID(params.id);

        let us = auth.appClients.filter(client => client.id === params.id);
        setEditClient({
          ...editClient,
          id: us[0].id,
          name: us[0].name,
          email: us[0].email,
          password: null,
          earning: us[0].earning,
          frequency: us[0].frequency,
          company: us[0].company,
          description: us[0].description,
          profile: us[0].thumbnail !== '' ? us[0].thumbnail : us[0].image
        })
      }

    }
  }, [auth.appClients]);

  const addClientProfile = (e) => {
    e.preventDefault();
    setProfileStatus(true);

    const form = new FormData();
    form.append('user_id', editClient.id);
    form.append('file', e.target.files[0]);
    dispatch(changeClientProfile(form, setProfileStatus));
  }

  const onFormUpdate = (e) => {
    e.preventDefault();
    setLoader(true);

    dispatch(editAppClient(editClient, setLoader));
  }

  /** Custom Sweetalert */

  const onConfirmCustom = (e) => {
    history.push(`edit-client/${clientId}`);
  }

  const onCancelCustom = (e) => {
    history.push('/clients');
  }

  // Fetch Client Data
  useEffect(() => {
    dispatch(fetchSocialAccounts(auth.token));
  }, [])

  return (
    <>
      <section className="form-card">
        <div className="container">
          <Alert />
          <div className="row">
            <div className="col-lg-5">
              {
                checkClient ?
                  <form method="post" onSubmit={(e) => onFormUpdate(e)}>
                    <div className="user-img">
                      <input type="file" id="file1" name="image" accept="image/*" capture onChange={(e) => addClientProfile(e)} />
                      <img src={editClient.profile !== null ? editClient.profile : user1} id="upfile1" style={{ cursor: 'pointer' }} />
                      <div className={`${profileStatus === false ? 'hide-social' : ''}`} style={{ position: "absolute", top: "72px" }}>
                        <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "30px", color: "#16d489" }} />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <small id="emailHelp" className="form-text text-muted">Name</small>
                      <input type="text" className="form-control" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Client Name"
                        required name="name" onChange={(e) => onInputEditChange(e)}
                        defaultValue={editClient.name}
                      />
                    </div>
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Current Email Address</small>
                      <input type="email" className="form-control" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Enter Current Email Address"
                        required name="email" onChange={(e) => onInputEditChange(e)}
                        readOnly defaultValue={editClient.email}
                      />
                    </div>
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Password (leave blank for same password)</small>
                      <input type="password" className="form-control" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Enter Current Password"
                        required name="password" onChange={(e) => onInputChange(e)}
                      />
                      {passwordError && <div className="text-danger">{passwordError}</div>}
                    </div>
                    <div className="form-row">
                      <div className="col">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">$</span>
                          </div>
                          <div className="form-group ml-1 p-0">
                            <small id="emailHelp" className="form-text text-muted">
                              Earning
                              <i
                                className="fa fa-info-circle ml-1"
                                data-for="mainEarn1"
                                data-iscapture="true"
                                data-tip="Earning from this client"
                              ></i>
                            </small>
                            <input type="number" min="0" className="form-control" id="exampleInputEmail1"
                              aria-describedby="emailHelp" required name="earning"
                              onChange={(e) => onInputEditChange(e)}
                              defaultValue={editClient.earning}
                            />
                            <ReactTooltip
                              id="mainEarn1"
                              place="right"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <small id="emailHelp" className="form-text text-muted">Frequency</small>
                          <select className="form-control" id="exampleFormControlSelect1" required name="frequency"
                            onChange={(e) => onInputEditChange(e)}
                          >
                            <option selected={editClient.frequency == 'weekly'} value="weekly">Weekly</option>
                            <option selected={editClient.frequency == 'monthly'} value="monthly">Monthly</option>
                            <option selected={editClient.frequency == 'yearly'} value="yearly">Yearly</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Company</small>
                      <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                        placeholder="Enter Clients Company Name" name="company" onChange={(e) => onInputEditChange(e)}
                        defaultValue={editClient.company}
                      />
                    </div>
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Description</small>
                      <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Clients Description"
                        rows={2} defaultValue={""} name="description" onChange={(e) => onInputEditChange(e)}
                        value={editClient.description}
                      >{editClient.description}</textarea>
                    </div>
                    <div className="creat-btn mt-4 d-flex justify-content-end">
                      <button type="submit" className="button btn-submit" disabled={loader || passwordError}>
                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Update
                      </button>
                    </div>
                  </form>
                  :
                  <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                    <div className="user-img">
                      <input type="file" id="file1" name="image" accept="image/*" capture style={{ display: 'none' }} />
                      <img src={user1} id="upfile1" />
                      {/* <img src="../../images/user1.png" alt="" id="upfile1" /> */}
                    </div>
                    <div className="form-group mt-3">
                      <small id="emailHelp" className="form-text text-muted">Name</small>
                      <input type="text" className="form-control" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Client Name"
                        required name="name" onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Current Email Address</small>
                      <input type="email" className="form-control" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Enter Current Email Address"
                        required name="email" onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Password</small>
                      <input type="password" className="form-control" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Enter Current Password"
                        required name="password" onChange={(e) => onInputChange(e)}
                      />
                      {passwordError && <div className="text-danger">{passwordError}</div>}
                    </div>
                    <div className="switch-box" style={{ display: "flex" }}>
                      <div className="form-check form-switch mb-3">
                        <input className="form-check-input" name="send" type="checkbox" value="1" checked={client.send} onChange={(e) => onCheckMail(e)} />
                      </div>
                      <label style={{ fontSize: ".875em" }}>Email credentials to this client</label>
                    </div>


                    <div className="form-row">
                      <div className="col">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">$</span>
                          </div>
                          <div className="form-group ml-1 p-0">
                            <small id="emailHelp" className="form-text text-muted">Earning
                              <i
                                className="fa fa-info-circle ml-1"
                                data-for="mainEarn"
                                data-iscapture="true"
                                data-tip="Earning from this client"
                              ></i>
                            </small>
                            <input type="number" min="0" className="form-control" id="exampleInputEmail1"
                              aria-describedby="emailHelp" required name="earning"
                              onChange={(e) => onInputChange(e)}
                            />
                            <ReactTooltip
                              id="mainEarn"
                              place="right"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <small id="emailHelp" className="form-text text-muted">Frequency</small>
                          <select className="form-control" id="exampleFormControlSelect1" required name="frequency"
                            onChange={(e) => onInputChange(e)}
                          >
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="yearly">Yearly</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Company</small>
                      <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                        placeholder="Enter Clients Company Name" name="company"
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Description</small>
                      <textarea className="form-control" id="exampleFormControlTextarea1"
                        placeholder="Clients Description"
                        rows={2} defaultValue={""} name="description" onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div className="creat-btn mt-4 d-flex justify-content-end">
                      <button type="submit" className="button btn-submit" disabled={loader || passwordError}>
                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Create
                      </button>

                    </div>
                  </form>
              }
            </div>
            <div className="col-lg-7">
              <div className="user-data">
                <span>Connect Social Accounts</span>
                <ul className="list-inline mt-3 d-flex justify-content-between">
                  <li>
                    <FacebookConnect checkAccount={checkAccount} editClient={editClient} />
                  </li>
                  <li>
                    <LinkedInConnect checkAccount={checkAccount} editClient={editClient} />
                  </li>
                  <li>
                    <TwitterConnect checkAccount={checkAccount} editClient={editClient} />
                  </li>
                  <li>
                    <Youtube checkAccount={checkAccount} editClient={editClient} />
                  </li>
                  <li>
                    <VimeoConnect checkAccount={checkAccount} editClient={editClient} />
                  </li>
                  <li>
                    <RedditConnect checkAccount={checkAccount} editClient={editClient} />
                  </li>
                  <li>
                    <TelegramConnect checkAccount={checkAccount} editClient={editClient} />
                  </li>
                </ul>
                <ul className="list-inline mt-3 d-flex justify-content-center">
                  <li className="mr-2">
                    <WordpressConnect checkAccount={checkAccount} editClient={editClient} />
                  </li>
                  <li>
                    <MediumConnect checkAccount={checkAccount} editClient={editClient} />
                  </li>
                </ul>
                <span>Manage</span>
                <table className="table table-striped  table-hover mt-3">
                  <thead className="theadbgcolor text-white text-center">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">ACCOUNT</th>
                      <th scope="col">NAME</th>
                      <th scope="col">DATE</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      socials.length > 0 ?
                        Object.entries(props).length !== 0 ?
                          socials.filter(social => social.user_id === props.id.match.params.id).map((social, index) => {
                            return (
                              <tr className="table-light">
                                <td>{index + 1}</td>
                                <td>{social.category !== null ? social.category : social.type}</td>
                                <td>{social.username}</td>
                                <td>{social.created}</td>
                                <td>
                                  <a className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1"
                                    style={{ cursor: "pointer", color: "red" }}
                                    onClick={(e) => onButtonClick(e, social.id)}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </a>
                                </td>
                              </tr>
                            )
                          })
                          : ''
                        :
                        <tr align="center">
                          <td colSpan={5}>No records found</td>
                        </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>


      <SweetAlert
        warning
        showCancel
        confirmBtnText={button}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="light"
        title="Are you sure?"
        onConfirm={(e) => onConfirm(e)}
        onCancel={(e) => onCancel(e)}
        focusCancelBtn
        show={showDelete}
      >
        Do you want to delete this Social account
      </SweetAlert>

      <SweetAlert
        success
        showCancel
        showCloseButton
        confirmBtnText="Continue"
        cancelBtnText="Later"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="light"
        title="Client Created Successfully"
        onConfirm={(e) => onConfirmCustom(e)}
        onCancel={(e) => onCancelCustom(e)}
        show={openCustomSweet}
      >
        Do you want to connect social accounts now ?
      </SweetAlert>


    </>
  )
}

export default AddFrom;
